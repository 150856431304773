import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Menu from '../components/Menu'
import SEO from '../components/seo'

const IndexPage = ({data}) => {
  const [ windowHeight, setWindowHeight ] = useState('100vh')

  const handleSetWindowHeight = () => {
    setWindowHeight(window.innerHeight)
  }

  useEffect(() => {
    handleSetWindowHeight()
    window.addEventListener('resize', handleSetWindowHeight)

    return () => {
      window.removeEventListener("resize", handleSetWindowHeight);
    }
  })

  return (
    <Layout windowHeight={windowHeight}>
      <SEO title='Index' />
      <div id='mainContent'>
        <Menu 
          data={data.contentfulMenu.entries} 
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MenuQuery {
    contentfulMenu {
      entries {
        id
        title
        slug
        year
        assets {
          id
          description
          fixed(width:300) {
            src
            srcSet
            srcWebp
            srcSetWebp
          } 
          file {
            url
            fileName
            contentType
          }
        }
      }
    }
  }
`

export default IndexPage
